
// Libraries
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component({})
export default class PlaceHolderCircles extends Vue {
  @Prop({ type: String, default: "left" }) marginType!: string;

  get placeHolderCircleClasses() {
    return {
      "placeholder-circles": true,
      "placeholder-circles-left": this.marginType === "left",
      "placeholder-circles-bottom": this.marginType === "bottom",
    };
  }
}
